import React from 'react';
import 'twin.macro';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '~components/Layout';
import SEO from '~components/SEO';
import Hero from '~components/Hero';
import Heading, { StyledHeading } from '~components/Heading';
import { IconCard } from '~components/Card';
import CaptionImage from '~components/CaptionImage';
import Values from '~components/Values';

import Access from '~components/Svg/Access';
import Bag from '~components/Svg/Bag';
import Check from '~components/Svg/Check';
import Directions from '~components/Svg/Directions';
import Globe from '~components/Svg/Globe';
import Pointer from '~components/Svg/Pointer';

import { roundedImg } from '~theme';

import { VARIANT } from '../enums';

const ValuesPage: React.FC = () => (
  <Layout>
    <SEO title="Vision, Mission & Values" desc="" pathname="/vision" />
    <Hero title="Vision, Mission & Values">
      <StaticImage src="../images/joyce-centre.jpg" alt="" layout="fullWidth" />
    </Hero>
    <div tw="bg-green mt-32 py-12">
      <div tw="container">
        <div tw="grid gap-6 items-center lg:(grid-cols-3)">
          <div tw="text-center lg:col-span-2">
            <Heading level="h2">Vision</Heading>
            <p tw="text-xl md:text-2xl">Future Ready. Learning for Life.</p>
          </div>
          <div tw="lg:(-my-32 col-span-1 text-right text-white)">
            <CaptionImage
              variant={VARIANT.ORANGE}
              caption="Pathfinder program focused on setting up students for success."
            >
              <StaticImage
                style={roundedImg}
                src="../images/guiding-a-student-2.jpg"
                alt=""
                layout="fullWidth"
              />
            </CaptionImage>
          </div>
        </div>
      </div>
    </div>
    <div tw="bg-red text-gray-50 mb-32 py-12">
      <div tw="container">
        <div tw="grid gap-10 items-center lg:(grid-cols-3)">
          <div tw="text-center lg:(col-span-2 order-2)">
            <Heading level="h2">Mission</Heading>
            <p tw="text-xl md:text-2xl">
              We educate and prepare highly skilled graduates for success and contribution to
              community, Canada and the world.
            </p>
          </div>
          <div tw="text-white lg:(-my-32 text-gray-800 order-1 col-span-1)">
            <CaptionImage variant={VARIANT.GREEN} caption="Students working on a plane.">
              <StaticImage
                style={roundedImg}
                src="../images/student-working-on-plane.jpg"
                alt=""
                layout="fullWidth"
              />
            </CaptionImage>
          </div>
        </div>
      </div>
    </div>
    <div tw="container">
      <StyledHeading>Our Values</StyledHeading>
      <Values />
    </div>
  </Layout>
);

export default ValuesPage;
